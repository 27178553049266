import React from "react";
import styles from "./ErrorPage.module.css";
import Footer from "./Footer";
import { colors } from "../utils/colors";
import Button from "./Button/Button";

const ErrorPage: React.FC = () => {
  return (
    <div className={styles.pageWrapper}>
      <div className={styles.errorContainer}>
        <video autoPlay loop muted className={styles.backgroundVideo}>
          <source src="/error_animation.mp4" type="video/mp4" />
        </video>

        <div className={styles.content}>
          <div className={styles.messageWrapper}>
            <div className={styles.messageBox}>
              <h1 style={{ color: colors.navBarGreen }}>Ocorreu um erro</h1>
              <p>Lamentamos o incómodo mas aconteceu algo inesperado.</p>
              <p>
                Parece que a página não está disponível ou pode ter ocorrido um
                erro no nosso sistema.
              </p>
              <p>
                É possível que o endereço tenha migrado, que o link inserido
                esteja incorreto ou haja um problema com a sua ligação à
                internet.
              </p>
              <p>
                Se o problema persistir, por favor, contacte o nosso suporte.
              </p>
              <p>Agradecemos a compreensão.</p>
            </div>
            <Button
              buttonStyles={{
                padding: "16px",
                backgroundColor: colors.navBarGreen
              }}
              onClick={() => (window.location.href = "/")}
              label="Voltar à página principal"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ErrorPage;
